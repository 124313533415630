<template>
    <div>
        <!-- 登录弹框 -->
        <el-dialog :visible.sync="loginAndCreateDialog" center :close-on-click-modal="false" @closed="closeDialog()" custom-class="dialog_normal" :append-to-body="true">
            <div class="dialog_cont">
                <div class="popTitle">Login to Your Account</div>
                <el-tabs v-model="tabType" class="login_tabs flex_center_col">
                    <el-tab-pane label="Sign in" name="login">
                        <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="accunt_form">
                            <div class="err_bg" v-show="errFlag">
                                <img src="../assets/img/err_ic.png" />
                                {{ errLogTxt }}
                            </div>
                            <el-form-item prop="username" class="fist_form_item">
                                <el-input v-model="loginForm.username" placeholder="Email" maxlength="50"></el-input>
                            </el-form-item>
                            <el-form-item prop="password" class="last_form_item">
                                <el-input v-model.trim="loginForm.password" :type="type" placeholder="password" @focus="errFlag = false" maxlength="20" @keyup.enter.native="submitLoginForm('loginForm')">
                                    <i slot="suffix" class="icon_watch" :class="inputType ? 'icon_watchun' : 'icon_watchon'" @click="inputType = !inputType"></i>
                                </el-input>
                            </el-form-item>
                            <div class="cur_pointer" @click="goToForgotPass()">Forgot password?</div>
                            <el-form-item prop="checked" class="log_check">
                                <el-checkbox v-model="loginForm.checked">
                                    I have read and agreed to CSI's
                                    <span @click="agree()" class="forgot_txt">Privacy Policy</span>
                                    And&nbsp;
                                    <span @click="agree()" class="forgot_txt">Terms & &nbsp; Conditions.</span>
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="btn_form_log" :class="{ blue: loginForm.checked }" @click="submitLoginForm('loginForm')">Sign in</el-button>
                                <!-- <div class="phone_page_bg">
                                    <div class="Quicklogin phone_page_bg">Quick login</div>
                                    <div class="Quicklogin-img">
                                        <fb-signin-button :params="fbSignInParams" @success="onSignInSuccess" @error="onSignInError">
                                            <img src="../assets/img/facebookfacebook52.png" alt="" />
                                        </fb-signin-button>
                                        <img class="linkending" @click="lingIn" src="../assets/img/a-05linkedin.png" alt="" />
                                    </div>
                                </div> -->
                                <!-- <el-button class="btn_form_log" :class="{blue:loginForm.checked}" @click="facebooklogin('loginForm')">快捷登录</el-button> -->
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="Create an Account" name="createAccount">
                        <el-form :model="createAccountForm" :rules="createAccountRules" ref="createAccountForm" class="accunt_form">
                            <div class="err_bg1" v-show="errCreateFlag">
                                <img src="../assets/img/err_ic.png" />
                                {{ errCreateTxt }}
                            </div>
                            <div class="err_forgot1 tip_sucess" v-show="sucessCreaSendCode">
                                <img src="../assets/img/sucess_ic.png" />
                                {{ sucessCreaSendCodeTxt }}
                            </div>
                            <el-form-item prop="account" class="fist_form_item1">
                                <el-input v-model="createAccountForm.account" placeholder="Email" maxlength="50"></el-input>
                            </el-form-item>
                            <el-form-item prop="passwordOne">
                                <el-input v-model.trim="createAccountForm.passwordOne" :type="type" placeholder="password" @focus="errFlag = false" maxlength="20">
                                    <i slot="suffix" class="icon_watch" :class="inputType ? 'icon_watchun' : 'icon_watchon'" @click="inputType = !inputType"></i>
                                </el-input>
                            </el-form-item>
                            <div class="intensity">
                                <span class="progress" :class="[level.includes('low') ? 'low' : '']"></span>
                                <span class="progress" :class="[level.includes('middle') ? 'middle' : '']"></span>
                                <span class="progress" :class="[level.includes('high') ? 'high' : '']"></span>
                                Password shall not be less than 6 digit
                                 <div class="warning_txt">  Password shall not be less than 6 digits</div>
                                <!-- <div class="warning_txt">The password must contain numbers, upper and lower case letters, 6-20 digits</div> -->
                            </div>
                            <el-form-item prop="passwordTwo">
                                <el-input v-model.trim="createAccountForm.passwordTwo" placeholder="Confirm Password" @focus="errFlag = false" :type="conInputType" maxlength="20">
                                    <i slot="suffix" class="icon_watch" :class="conInputType != 'password' ? 'icon_watchun' : 'icon_watchon'" @click="showconfirmPwd"></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="phone">
                                <el-input v-model="createAccountForm.phone" placeholder="Phone Number" maxlength="50"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-input id="code" v-model="createAccountForm.code" class="send_code_mar" placeholder="Verification Code" maxlength="20"></el-input>
                                <span class="send_txt" v-show="setTimeFlag" @click="sendCode">Send verification code</span>
                                <div class="send_time" v-show="!setTimeFlag">{{ setTime }}s</div>
                            </el-form-item>
                            <el-form-item prop="checked" class="log_check">
                                <el-checkbox v-model="createAccountForm.checked">
                                    I have read and agreed to CSI's
                                    <span @click="agree()" class="forgot_txt">Privacy Policy</span>
                                    And&nbsp;
                                    <span @click="agree()" class="forgot_txt">Terms & &nbsp; Conditions.</span>
                                </el-checkbox>
                            </el-form-item>
                            <!-- btn_mar_top -->
                            <el-form-item>
                                <el-button class="btn_form_log" :class="{ blue: createAccountForm.checked }" @click="submitCreateForm('createAccountForm')">Create Account</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>

        <!-- 忘记密码弹框 -->
        <el-dialog :visible.sync="forgotDialog" center :close-on-click-modal="false" @closed="closeDialog()" custom-class="dialog_normal" :append-to-body="true">
            <div class="dialog_cont">
                <div class="err_forgot tip_sucess" v-show="errFlagForSucess">
                    <img src="../assets/img/sucess_ic.png" />
                    {{ sussFlagForErrTxt }}
                </div>
                <div class="err_forgot tip_error" v-show="errFlagForErr">
                    <img src="../assets/img/err_ic.png" />
                    {{ errFlagForErrTxt }}
                </div>
                <div class="popTitle">Forgot Password ?</div>
                <el-form :model="forgotForm" :rules="forgotFormRules" ref="forgotForm">
                    <div class="step1" v-show="step1Show">
                        <el-form-item prop="account" class="fist_form_item">
                            <el-input v-model="forgotForm.account" placeholder="Email" maxlength="50"></el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-input v-model="forgotForm.code" class="send_code_mar" placeholder="Verification Code" maxlength="20"></el-input>
                            <span class="send_txt" v-show="setTimeForFlag" @click="sendCodeForgot">
                                Send
                                <br />
                                verification code
                            </span>
                            <div class="send_time" v-show="!setTimeForFlag">{{ setTimeFor }}s</div>
                        </el-form-item>
                        <el-form-item class="btn_mar_top">
                            <el-button class="btn_form_log" :class="{ blue: true }" @click="next()">Next Step</el-button>
                        </el-form-item>
                    </div>
                    <div class="step2" v-show="step2Show">
                        <el-form-item prop="newPasswordOne" class="fist_form_item">
                            <el-input v-model.trim="forgotForm.newPasswordOne" :type="type" placeholder="password" @focus="errFlag = false" maxlength="20">
                                <i slot="suffix" class="icon_watch" :class="inputType ? 'icon_watchun' : 'icon_watchon'" @click="inputType = !inputType"></i>
                            </el-input>
                        </el-form-item>
                        <div class="intensity">
                            <span class="progress" :class="[level.includes('low') ? 'low' : '']"></span>
                            <span class="progress" :class="[level.includes('middle') ? 'middle' : '']"></span>
                            <span class="progress" :class="[level.includes('high') ? 'high' : '']"></span>
                            <div class="warning_txt">The password must contain numbers, upper and lower case letters, 8-20 digits</div>
                        </div>
                        <el-form-item prop="newPasswordTow">
                            <el-input v-model.trim="forgotForm.newPasswordTow" placeholder="Confirm Password" @focus="errFlag = false" :type="conInputType" maxlength="20">
                                <i slot="suffix" class="icon_watch" :class="conInputType != 'password' ? 'icon_watchun' : 'icon_watchon'" @click="showconfirmPwd"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="btn_mar_top">
                            <el-button class="btn_form_log" :class="{ blue: true }" @click="submitForgotForm('forgotForm')">Confirm</el-button>
                        </el-form-item>
                    </div>
                    <div class="step3" v-show="step3Show">
                        <div class="step3_txt">The password has been modified successfully. Do you want to return to the login page?</div>
                        <el-form-item>
                            <el-button class="btn_form_log" :class="{ blue: true }" @click="goToLogin()">Sign in</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <!-- 条款信息 -->
        <el-dialog :visible.sync="Agreement" center :close-on-click-modal="false" @closed="closeDialogagree()" custom-class="dialog_normal2" style="margin-top: -50px" :append-to-body="true">
            <div class="agreement_cont">
                <el-tabs :tab-position="window.innerWidth < 821 ? 'top' : 'left'">
                    <el-tab-pane label="Privacy Policy">
                        <div style="margin-left: 20px">
                            <h1 class="info1">Privacy Policy</h1>
                            <br />
                            <br />
                            <br />
                            <div class="info1box">
                                <div>Our Privacy Policy is designed to assist you in understanding how we collect and use the personal information you provide to us and to assist you in making informed decisions when using our site and our products and services.</div>
                                <br />

                                <h2 class="question">What Information Do We Collect?</h2>

                                <br />
                                <div>When you visit our website, you may provide us with two types of information: personal information you knowingly choose to disclose that is collected on an individual basis and Website use information collected on an aggregate basis as you and others browse our website.</div>
                                <br />
                                <br />
                                <span class="answer-title">1. Personal Information You Choose to Provide</span>
                                <br />
                                <br />
                                <div class="answer-content">
                                    <span class="answer-title-italic">Registration Information.</span>
                                    <br />
                                    <br />
                                    <span>When you visit our website, you may provide us with two types of information: personal information you knowingly choose to disclose that is collected on an individual basis and Website use information collected on an aggregate basis as you and others browse our website.</span>
                                    <br />
                                    <br />
                                    <span class="answer-title-italic">Email Information.</span>
                                    <br />
                                    <br />
                                    <span>If you choose to correspond with us through email, we may retain the content of your email messages together with your email address and our responses.</span>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <span class="answer-title">2. Website Use Information</span>
                                <div class="answer-content">
                                    <br />
                                    <span>Similar to other commercial websites, our website utilizes a standard technology called "cookies" (see explanation below, "What Are Cookies?") and Web server logs to collect information about how our website is used. Information gathered through cookies and Web server logs may include the date and time of visits, the pages viewed, time spent at our website, and the Websites visited just before and just after our website. We, our advertisers and ad serving companies may also use small technology or pieces of code to determine which advertisements and promotions users have seen and how users responded to them.</span>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <h2 class="question">How Do We Use the Information That You Provide to Us?</h2>
                                <br />
                                <span>Broadly speaking, we use personal information for purposes of administering and expanding our business activities, providing customer service and making available other products and services to our customers and prospective customers. Occasionally, we may also use the information we collect to notify you about important changes to our website, new services and special offers we think you will find valuable. You may notify us at any time if you do not wish to receive these updates and/or offers by emailing us at</span>
                                <div><a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a></div>
                                <br />
                                <h2 class="question">What Are Cookies?</h2>
                                <br />
                                <span>A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a website, that site's computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each Website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a Website to access the cookies it has already sent to you, not the cookies sent to you by other sites. Some of our business partners (e.g., advertisers) use cookies that originate from their sites. We have no access or control over those cookies.</span>
                                <br />
                                <br />
                                <h2 class="question">How Do We Use Information We Collect from Cookies?</h2>
                                <br />
                                <span>As you use our website, the site uses its cookies to differentiate you from other users. In some cases, we also use cookies to prevent you from seeing unnecessary advertisements or requiring you to log in more than once for security reasons. Cookies, in conjunction with our Web server's log files, allow us to calculate the aggregate number of people visiting our website and which parts of the site are most popular. This helps us gather feedback in order to constantly improve our website and better serve our customers. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provided to us in your cookies.</span>
                                <br />
                                <br />
                                <div class="answer-content">
                                    <span class="answer-title">Sharing Information with Third Parties</span>
                                    <br />
                                    <br />
                                    <span>
                                        We may enter into alliances, partnerships or other business arrangements with third parties who may be given access to personal information including your name, address, telephone number and email for the purpose of providing you information regarding products and services that we think will be of interest to you. In connection with alliances, partnerships or arrangements, we may also provide certain information to third parties if we have determined that the information will be used in a responsible manner by a responsible third party. For example, some of our partners operate stores or provide services on our site, while others power offerings developed by us for your use. We also use third parties to facilitate our business, including, but not
                                        limited to, sending email and processing credit card payments. In connection with these offerings and business operations, our partners and other third parties may have access to your personal information for use in connection with the business activities. As we develop our business, we may buy or sell assets or business offerings. Customer, email, and visitor information is generally one of the transferred business assets in these types of transactions. We may also transfer such information in the course of corporate divestitures, mergers, or any dissolution.
                                    </span>
                                    <br />
                                    <br />
                                    <span class="answer-title">Notice of New Services and Changes</span>
                                    <br />
                                    <br />
                                    <span>Occasionally, we may also use the information we collect to notify you about important changes to our website, new services and special offers we think you will find valuable. As our customer, you will be given the opportunity to notify us if you would not like to receive these offers, by sending us an email request at</span>
                                    <div><a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a></div>
                                    <br />
                                    <br />
                                </div>
                                <h2 class="question">How Do We Secure Information Transmissions?</h2>
                                <br />
                                <span>Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. Some of the information you may enter on our website may be transmitted securely via Secure Sockets Layer SSL, 128-bit encryption services. Pages utilizing this technology will have URLs that start with HTTPS instead of HTTP. Please contact</span>
                                <div>
                                    <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                    <span>if you have any questions or concerns.</span>
                                </div>
                                <br />
                                <h2 class="question">How Can You Access and Correct Your Information?</h2>
                                <br />
                                <span>You may request access to all your personally identifiable information that we collect online and maintain in our database by emailing</span>
                                <div><a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a></div>
                                <br />
                                <div class="answer-content">
                                    <span class="answer-title">Certain Disclosures</span>
                                    <br />
                                    <br />
                                    <span>We may disclose your personal information if required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law or comply with legal process served on us or affiliated parties; (b) protect and defend our rights and property, our site, the users of our site, and/or our affiliated parties; (c) act under circumstances to protect the safety of users of our site, us, or third parties.</span>
                                </div>
                                <br />
                                <br />
                                <h2 class="question">What About Other Websites Linked to Our Website?</h2>
                                <br />
                                <span>We are not responsible for the practices employed by Websites linked to or from our website nor the information or content contained therein. Often links to other Websites are provided solely as pointers to information on topics that may be useful to the users of our website.</span>
                                <br />
                                <br />
                                <span>Please remember that when you use a link to go from our Website to another Website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other Website, including Websites which have a link on our Website, is subject to that Website's own rules and policies. Please read those rules and policies before proceeding.</span>
                                <br />
                                <br />
                                <div class="answer-content">
                                    <span class="answer-title">Your Consent</span>
                                    <br />
                                    <br />
                                    <span>By using our website, you consent to our collection and use of your personal information as described in this Privacy Policy. If we change our privacy policies and procedures, we will post those changes on our website to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it.</span>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Terms of Use Agreement">
                        <div style="margin-left: 20px">
                            <h1 class="info1">Terms of Use Agreement</h1>
                            <br />
                            <br />
                            <br />
                            <div class="info1box">
                                <div>By using our site, you are agreeing to comply with and be bound by the following terms of use. Please review the following terms carefully. If you do not agree to these terms, you should not use this site. The term ‘CSIbizInfo’ or “us” or “we” or “our” refers to ‘Company Search Incorporated’, the owner of the Website. The term “you” refers to the user or viewer of our website.</div>
                                <br />

                                <h2 class="question">Acceptance of Agreement</h2>

                                <br />
                                <div>You agree to the terms and conditions outlined in this Terms of Use Agreement ("Agreement") with respect to our website (the "Site"). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Site, the content, products or services provided by or through the Site, and the subject matter of this Agreement. This Agreement may be amended at any time, at our absolute discretion, from time to time without specific notice to you. The latest Agreement will be posted on the Site, and you should review this Agreement prior to using the Site.</div>
                                <br />
                                <br />

                                <h2 class="question">Service Marks</h2>
                                <br />
                                <span>‘CSIbizInfo.com’ and others are our service marks, registered service marks or trademarks. Other product and company names mentioned on the Site may be trademarks of their respective owners.</span>
                                <br />
                                <br />
                                <h2 class="question">Limited License; Permitted Uses</h2>
                                <br />
                                <span>You are granted a non-exclusive, non-transferable, revocable license (a) to access and use the Site strictly in accordance with this Agreement; (b) to use the Site solely for internal, personal, non-commercial purposes; and (c) to print out discrete information (or electronic version) from the Site solely for internal, personal, non-commercial purposes and provided that you maintain all copyright and other policies contained therein. No print out or electronic version of any part of the Site or its contents may be used by you in any litigation or arbitration matter whatsoever under any circumstances.</span>
                                <br />
                                <br />
                                <h2 class="question">Restrictions and Prohibitions on Use</h2>
                                <br />
                                <span>
                                    Your license for access and use of the Site and any information, materials or documents (collectively defined as “Content and Materials”) therein are subject to the following restrictions and prohibitions on use: You may not (a) copy, print (except for the express limited purpose permitted above), republish, display, distribute, transmit, sell, rent, lease, loan or otherwise make available in any form or by any means all or any portion of the Site or any Content and Materials retrieved there from; (b) use the Site or any materials obtained from the Site to develop, of as a component of, any information, storage and retrieval system, database, information base, or similar resource (in any media now existing or hereafter developed), that is offered for
                                    commercial distribution of any kind, including through sale, license, lease, rental, subscription, or any other commercial distribution mechanism; (c) create compilations or derivative works of any Content and Materials from the Site; (d) use any Content and Materials from the Site in any manner that may infringe any copyright, intellectual property right, proprietary right, or property right of us or any third parties; (e) remove, change or obscure any copyright notice or other proprietary notice or terms of use contained in the Site; (f) make any portion of the Site available through any timesharing system, service bureau, the Internet or any other technology now existing or developed in the future; (g) remove, decompile, disassemble or reverse
                                    engineer any Site software or use any network monitoring or discovery software to determine the Site architecture; (h) use any automatic or manual process to harvest information from the Site; (i) use the Site for the purpose of gathering information for or transmitting (1) unsolicited commercial email; (2) email that makes use of headers, invalid or nonexistent domain names, or other means of deceptive addressing; and (3) unsolicited telephone calls or facsimile transmissions; (j) use the Site in a manner that violates any state or federal law regulating email, facsimile transmissions or telephone solicitations; and (k) export or re-export the Site or any portion thereof, or any software available on or through the Site.
                                </span>
                                <br />
                                <br />

                                <h2 class="question">Forms, Agreements & Documents</h2>
                                <br />
                                <span>
                                    We may make available through the Site or through other Websites sample and actual forms, checklists, business documents and legal documents (collectively, “Documents”). All Documents are provided on a non-exclusive license basis only for your personal one-time use for non-commercial purposes, without any right to re-license, sublicense, distribute, assign or transfer such license. Documents are provided for a charge and without any representations or warranties, express or implied, as to their suitability, legal effect, completeness, currentness, accuracy, and/or appropriateness. The Documents are provided “as is”, “as available”, and with “all faults”, and we and any provider of the Documents disclaim any warranties, including but not limited to the
                                    warranties of merchantability and fitness for a particular purpose. The Documents may be inappropriate for your particular circumstances. Furthermore, state or provincial laws may require different or additional provisions to ensure the desired result. You should consult with legal counsel to determine the appropriate legal or business documents necessary for your particular transactions, as the Documents are only samples and may not be applicable to a particular situation. Some Documents are public domain forms or available from public records.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">No Legal Advice or Attorney-Client Relationship</h2>
                                <br />
                                <span>Information contained on or made available through the Site is not intended to and does not constitute legal advice, recommendations, mediation or counseling under any circumstance and no attorney-client relationship is formed. We do not warrant or guarantee the accurateness, completeness, adequacy or currentness of the information contained in or linked to the Site. Your use of information on the Site or materials linked to the Site is entirely at your own risk. We are not a law firm and the Site is not a lawyer referral service.</span>

                                <br />
                                <br />
                                <h2 class="question">Linking to the Site</h2>
                                <br />
                                <span>You may provide links to the Site, provided (a) that you do not remove or obscure, by framing or otherwise, advertisements, the copyright notice, or other notices on the Site, (b) your site does not engage in illegal or pornographic activities, and (c) you discontinue providing links to the Site immediately upon request by us.</span>
                                <br />
                                <br />
                                <h2 class="question">Advertisers</h2>
                                <br />
                                <span>The Site may contain advertising and sponsorships. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on the Site is accurate and complies with applicable laws. We are not responsible for the illegality or any error, inaccuracy or problem in the advertiser’s or sponsor’s materials.</span>
                                <br />
                                <br />
                                <h2 class="question">Registration</h2>
                                <br />
                                <span>Certain sections of, or offerings from, the Site may require you to register. If registration is requested, you agree to provide us with accurate, complete registration information. Your registration must be done using your real name and accurate information. Each registration is for your personal use only and not on behalf of any other person or entity. We do not permit: (a) any other person using the registered sections under your name; or (b) access through a single name being made available to multiple users on a network. You are responsible for preventing such unauthorized use and can be held accountable.</span>
                                <br />
                                <br />
                                <span>It is prohibited and, consequently, punishable by law when the user gives false personal details, if registering at the website or other ways of communication.</span>
                                <br />
                                <br />
                                <h2 class="question">Database:</h2>
                                <br />
                                <span>We source the information in our databases through a variety of means including: directly from government websites, from publicly available datasets, through Freedom of Information requests as well as data partners, with or without APIs. All of the data is converted into a usable resource enabling the user an easy workable search engine search engine We spend a lot of time, effort, and even money in getting this data and turning it into a usable resource workable and highly usable resource.</span>
                                <br />
                                <br />
                                <span>We do not claim any rights over the information we receive from our any of our sources, though claim rights over our database of this information.</span>
                                <br />
                                <br />
                                <h2 class="question">Availability of Products</h2>
                                <br />
                                <span>Access and/or Information on the Site might not be available in your location. If that occurs, it has to do with local laws and prohibitions. When that is the case, please contact</span>
                                <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                for more information.
                                <br />
                                <br />

                                <h2 class="question">Errors, Corrections and Changes</h2>
                                <br />
                                <span>We do not represent or warrant that the Site will be error-free, free of viruses or other harmful components, or that defects will be corrected. We do not represent or warrant that the information available on or through the Site will be correct, accurate, timely or otherwise reliable. We may make changes to the features, functionality or content of the Site at any time. We reserve the right in our sole discretion to edit or delete any documents, information or other content appearing on the Site.</span>
                                <br />
                                <br />
                                <h2 class="question">Third Party Content</h2>
                                <br />
                                <span>Third party content may appear on the Site or may be accessible via links from the Site. We are not responsible for and assume no liability for any mistakes, misstatements of law, defamation, omissions, falsehood, obscenity, pornography or profanity in the statements, opinions, representations or any other form of content on the Site. You understand that the information and opinions in the third-party content represent solely the thoughts of the author and is neither endorsed by nor does it necessarily reflect our belief.</span>
                                <br />
                                <br />
                                <h2 class="question">Unlawful Activity</h2>
                                <br />
                                <span>We reserve the right to investigate complaints or reported violations of this Agreement and to take any action we deem appropriate, including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to your profile, email addresses, usage history, posted materials, IP addresses and traffic information.</span>
                                <br />
                                <span>The user should not to do anything to harm CSIbizInfo’s reputation.</span>
                                <br />
                                <br />
                                <h2 class="question">Indemnification</h2>
                                <br />
                                <span>The user agrees to indemnify, defend and hold us and our partners, agents, officers, directors, employees, subcontractors, successors, assigns, third party suppliers of information and documents, attorneys, advertisers, product and service providers, and affiliates (collectively, "Affiliated Parties") harmless from any liability, loss, claim and expense, including reasonable attorney's fees, related to your violation of this Agreement or use of the Site.</span>
                                <br />
                                <br />
                                <h2 class="question">Nontransferable</h2>
                                <br />
                                <span>Your right to use the Site is not transferable or assignable. Any password or right given to you to obtain information or documents is not transferable or assignable.</span>
                                <br />
                                <br />
                                <h2 class="question">Limitation of Liability</h2>
                                <br />
                                <span>(a) We and any Affiliated Party shall not be liable for any loss, injury, claim, liability, or damage of any kind resulting in any way from (a) any errors in or omissions from the Site or any services or products obtainable there from, (b) the unavailability or interruption of the Site or any features thereof, (c) your use of the Site, (d) the content contained on the Site, or (e) any delay or failure in performance beyond the control of a Covered Party.</span>
                                <br />
                                <br />
                                <span>(b) The aggregate liability of us and the affiliated parties in connection with any claim arising out of or relating to the site and/or the products, information, documents and services provided herein or hereby shall not exceed USD100. And that amount shall be in lieu of all other remedies which you may have against us and any affiliated party.</span>
                                <br />
                                <br />
                                <h2 class="question">Use of Information</h2>
                                <br />
                                <span>
                                    We reserve the right, and you authorize us, to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with our Privacy Policy. All remarks, suggestions, ideas, graphics, or other information communicated by you to us (collectively, a "Submission") will forever be our property. We will not be required to treat any Submission as confidential, and will not be liable for any ideas (including without limitation, product, service or advertising ideas) and will not incur any liability as a result of any similarities that may appear in our future products, services or operations. Without limitation, we will have exclusive ownership of all present and future existing rights to the
                                    Submission of every kind and nature everywhere. We will be entitled to use the Submission for any commercial or other purpose whatsoever, without compensation to you or any other person sending the Submission. You acknowledge that you are responsible for whatever material you submit, and you, not us, have full responsibility for the message, including its legality, reliability, appropriateness, originality, and copyright.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">Third-Party Services</h2>
                                <br />
                                <span>
                                    We reserve the right, and you authorize us, to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with our Privacy Policy. All remarks, suggestions, ideas, graphics, or other information communicated by you to us (collectively, a "Submission") will forever be our property. We will not be required to treat any Submission as confidential, and will not be liable for any ideas (including without limitation, product, service or advertising ideas) and will not incur any liability as a result of any similarities that may appear in our future products, services or operations. Without limitation, we will have exclusive ownership of all present and future existing rights to the
                                    Submission of every kind and nature everywhere. We will be entitled to use the Submission for any commercial or other purpose whatsoever, without compensation to you or any other person sending the Submission. You acknowledge that you are responsible for whatever material you submit, and you, not us, have full responsibility for the message, including its legality, reliability, appropriateness, originality, and copyright.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">Third-Party Merchant Policies</h2>
                                <br />
                                <span>All rules, policies (including privacy policies) and operating procedures of Merchants will apply to you while on any Merchant sites. We are not responsible for information provided by you to Merchants. We and the Merchants are independent contractors and neither party has authority to make any representations or commitments on behalf of the other.</span>
                                <br />
                                <br />
                                <h2 class="question">Privacy Policy</h2>
                                <br />
                                <span>Our Privacy Policy is designed to assist you in understanding how we collect and use the personal information you provide to us and to assist you in making informed decisions when using our site and our products and services. The user can only access or request personal data when he/she/they have a lawful basis for doing so. The user has to be able to demonstrate the existence of that lawful basis.</span>
                                <br />
                                <br />
                                <h2 class="question">Payments</h2>
                                <br />
                                <span>You represent and warrant that if you are purchasing something from us or from Merchants that (i) any credit information you supply is true and complete, (ii) charges incurred by you will be honored by yourself or your credit card company, and (iii) you will pay the charges incurred by you at the posted prices, including any applicable taxes.</span>
                                <br />
                                <br />
                                <span>When the user fails to pay the amount due, the full amount will become due with immediate effect and any remaining credits will be frozen until the amount has been settled.</span>
                                <br />

                                <br />
                                <span>The user shall be liable to pay interest on the basis of 3% above the prime annual base lending rate of the Bank of America. The user agrees to fully indemnify “CSIbizInfo” against all third-party cost incurred in the pursuit of payment. This includes any additional bank charges.</span>
                                <br />
                                <br />
                                <h2 class="question">Securities Laws</h2>
                                <br />
                                <span>
                                    The Site may include statements concerning our operations, prospects, strategies, financial condition, future economic performance and demand for our products or services, as well as our intentions, plans and objectives (particularly with respect to product and service offerings), that are forward-looking statements. These statements are based upon a number of assumptions and estimates which are subject to significant uncertainties, many of which are beyond our control. When used on our Site, words like "anticipates," "expects," "believes," "estimates," "seeks," "plans," "intends," "will" and similar expressions are intended to identify forward-looking statements designed to fall within securities law safe harbors for forward-looking statements. The Site
                                    and the information contained herein do not constitute an offer or a solicitation of an offer for sale of any securities. None of the information contained herein is intended to be, and shall not be deemed to be, incorporated into any of our securities-related filings or documents.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">Links to other Websites</h2>
                                <br />
                                <span>The Site contains links to other Websites. We are not responsible for the content, accuracy or opinions express in such Websites, and such Websites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked Website on our Site does not imply approval or endorsement of the linked website by us. If you decide to leave our Site and access these third-party sites, you do so at your own risk.</span>
                                <br />
                                <br />
                                <h2 class="question">Copyrights and Copyright Agents</h2>
                                <br />
                                <span>We respect the intellectual property of others, and we ask you to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our Copyright Agent the following information:</span>
                                <br />
                                <br />
                                <ul class="answer-ul">
                                    <li>
                                        <span style="width: 25px">1.</span>
                                        <span>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</span>
                                    </li>
                                    <li>
                                        <span style="width: 25px">2.</span>
                                        <span>A description of the copyrighted work that you claim has been infringed;</span>
                                    </li>
                                    <li>
                                        <span style="width: 25px">3.</span>
                                        <span>A description of where the material that you claim is infringing is located on the Site;</span>
                                    </li>
                                    <li>
                                        <span style="width: 25px">4.</span>
                                        <span>Your address, telephone number, and email address;</span>
                                    </li>
                                    <li>
                                        <span style="width: 25px">5.</span>
                                        <span>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</span>
                                    </li>
                                    <li>
                                        <span style="width: 25px">6.</span>
                                        <span>A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</span>
                                    </li>
                                </ul>
                                <br />
                                <br />
                                <span>Our Copyright Agent for Notice of claims of copyright infringement on the Site can be reached by directing an e-mail to the Copyright Agent at</span>
                                <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                for more information.
                                <br />
                                <br />
                                <h2 class="question">Information and Press Releases</h2>
                                <br />
                                <span>The Site contains information and press releases about us. We disclaim any duty or obligation to update this information or any press releases. Information about companies other than ours contained in the press release or otherwise, should not be relied upon as being provided or endorsed by us.</span>
                                <br />
                                <br />
                                <h2 class="question">Legal Compliance</h2>
                                <br />
                                <span>You agree to comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Site and the Content and Materials provided therein.</span>
                                <br />
                                <br />
                                <h2 class="question">Refund and Return Policy</h2>
                                <br />
                                <span>To the extent that you purchase any goods or services directly from us, we will refund you your purchase price within 30 days of you notifying us in writing of your desire for the refund, together with the reason for the request, with the product or service returned to us in substantially the same condition as when purchased. Please note, however, that certain products and services mentioned on our site are sold by third parties or are linked to third party websites, and we have no responsibility or liability for those products or services. You may request a refund by contacting us by email at</span>
                                <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                <span>You may obtain any additional information concerning our refund and return policy, including our mailing address, by contacting us at</span>
                                <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                <br />
                                <br />
                                <h2 class="question">Warning Regarding Web Fraud and Phishing:</h2>
                                <br />
                                <span>Our name, brand(s) and reputation may be misused or abused by imposters, fake websites and those engaging in “phishing” scams, seeking personal or confidential information. If you have any questions about the before-mentioned, have doubts about the originality, please contact</span>
                                <a href="mailto:request@CSIbizInfo.com" class="amail-style">request@CSIbizInfo.com.</a>
                                <br />
                                <br />
                                <h2 class="question">Miscellaneous</h2>
                                <br />
                                <span>
                                    This Agreement shall be treated as though it were executed and performed in Dallas, Terxas, and shall be governed by and construed in accordance with the laws of the State of Texas (without regard to conflict of law principles). Any cause of action by you with respect to the Site (and/or any information, Documents, products or services related thereto) must be instituted within one (1) year after the cause of action arose or be forever waived and barred. The language in this Agreement shall be interpreted as to its fair meaning and not strictly for or against any party. This Agreement and all incorporated agreements and your information may be automatically assigned by us in our sole discretion to a third party in the event of an acquisition, sale or
                                    merger. Should any part of this Agreement be held invalid or unenforceable, that portion shall be construed consistent with applicable law and the remaining portions shall remain in full force and effect. To the extent that anything in or associated with the Site is in conflict or inconsistent with this Agreement, this Agreement shall take precedence. Our failure to enforce any provision of this Agreement shall not be deemed a waiver of such provision nor of the right to enforce such provision. Our rights under this Agreement shall survive any termination of this Agreement.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">Arbitration</h2>
                                <br />
                                <span>
                                    Any legal controversy or legal claim arising out of or relating to this Agreement or our services, excluding legal action taken by us to collect or recover damages for, or obtain any injunction relating to, Site operations, intellectual property, and our services, shall be settled solely by binding arbitration in accordance with the commercial arbitration rules of JAMS. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in San Francisco, California, and judgment on the arbitration award may be entered into any court having jurisdiction thereof. Either you or we may seek any interim or preliminary
                                    relief from a court of competent jurisdiction in Dallas, Texas necessary to protect the rights or property of you and us pending the completion of arbitration. Each party shall bear one-half of the arbitration fees and costs incurred through JAMS.
                                </span>
                                <br />
                                <br />
                                <h2 class="question">Force Majeure</h2>
                                <br />
                                <span>“CSIbizInfo” will not be liable for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen contingency or circumstances beyond the reasonable control of “CSIbizInfo”, including without limitation Internet outages, communications outages, fire, flood war or an act of God.</span>
                                <br />
                                <br />
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { doLogin, register, sendEmailCode, checkForgotCode, forgetPassword } from '@/api/api.js';
import { mapGetters, mapActions, mapState } from 'vuex';
import crypto from '../utils/crypto.js';
import bus from './bus';
// import axios from 'axios';
export default {
    props: {
        loginAndCreatePop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Incorrect password format'));
            } else if (value !== this.createAccountForm.passwordTwo) {
                callback(new Error('Confirm password is inconsistent with password'));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Incorrect password format'));
            } else if (value !== this.forgotForm.newPasswordTow) {
                callback(new Error('Confirm password is inconsistent with password'));
            } else {
                callback();
            }
        };
        //邮箱校验
        var validateEmail = (rule, value, callback) => {
            if (value !== '') {
                let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
                if (!reg.test(value)) {
                    callback(new Error('Incorrect account format'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        // 登陆勾选认证
        var checkedyanzheng = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please check the box to agree the term'));
            } else if (value == false) {
                callback(new Error('Please check the box to agree the term'));
            } else {
                callback();
            }
        };
        var checkedyanzheng1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please check the box to agree the term'));
            } else if (value == false) {
                callback(new Error('Please check the box to agree the term'));
            } else {
                callback();
            }
        };
        return {
            Agreement: false,
            //loginAndCreateDialog:false,//登录弹框
            forgotDialog: false, //忘记密码弹框
            timer: null,
            setTime: '',
            setTimeFlag: true, //发送倒计时显示
            timerFor: null,
            setTimeFor: '',
            setTimeForFlag: true,
            tabType: 'login',
            errFlag: false,
            errLogTxt: '',
            errCreateFlag: false,
            errCreateTxt: '',
            sucessCreaSendCode: false,
            sucessCreaSendCodeTxt: '',
            errFlagForSucess: false,
            errFlagForErr: false,
            errFlagForErrTxt: '',
            sussFlagForErrTxt: '',
            inputType: false, //查看密码
            conInputType: 'password', //查看密码
            level: [], //密码强度

            loginForm: {
                username: '',
                password: '',
                checked: '',
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        message: 'Incorrect account format',
                        trigger: 'blur',
                    },
                    { validator: validateEmail, trigger: 'blur' },
                ],
                password: [
                    {
                        required: true,
                        message: 'Incorrect password  format',
                        trigger: 'blur',
                    },
                ],
                checked: [
                    {
                        required: true,
                        // message: 'Please checked',
                        validator: checkedyanzheng,
                        trigger: 'blur',
                    },
                ],
            },
            createAccountForm: {
                account: '',
                passwordOne: '',
                passwordTwo: '',
                phone: '',
                code: '',
                checked: '',
            },
            createAccountRules: {
                account: [
                    {
                        required: true,
                        message: 'Incorrect account format',
                        trigger: 'blur',
                    },
                    {
                        validator: validateEmail,
                        trigger: 'blur',
                    },
                ],
                passwordOne: [
                    {
                        required: true,
                        validator: this.checkPassword,
                        trigger: 'change',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Incorrect phone  format',
                        trigger: 'blur',
                    },
                ],
                passwordTwo: [
                    {
                        required: true,
                        validator: validatePass,
                        trigger: 'blur',
                    },
                ],
                code: [
                    {
                        required: true,
                        message: 'The verification code is inaccurate or expired',
                        trigger: 'blur',
                    },
                ],
                checked: [
                    {
                        required: true,
                        validator: checkedyanzheng1,
                        // message: 'Please check the box to agree the term',
                        trigger: 'blur',
                    },
                ],
            },
            forgotForm: {
                account: '',
                code: '',
                newPasswordOne: '',
                newPasswordTow: '',
            },
            forgotFormRules: {
                account: [
                    {
                        required: true,
                        message: 'Incorrect account format',
                        trigger: 'blur',
                    },
                ],
                code: [
                    {
                        required: true,
                        message: 'The verification code is inaccurate or expired',
                        trigger: 'blur',
                    },
                ],
                newPasswordOne: [
                    {
                        required: true,
                        validator: this.checkPassword,
                        trigger: 'change',
                    },
                ],
                newPasswordTow: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: 'blur',
                    },
                ],
            },
            step1Show: true,
            step2Show: false,
            step3Show: false,
            fbSignInParams: {
                scope: 'email,user_likes,public_profile',
                enable_profile_selector: true,
                return_scopes: true,
            },
            window: window,
            savedState: '',
            apiKey: '86p20mqlao9hac',
            loggedIn: false,
            profileData: null,
        };
    },
    created() {
        bus.$on('handleShowLogin', loginStatus => {
            this.loginAndCreateDialog = loginStatus;
        });
        bus.$on('Agreement', loginStatus => {
            this.Agreement = loginStatus;
        });
        // 初始化Facebook SDK
        // window.fbAsyncInit = function () {
        //     window.FB.init({
        //         appId: '767514612088787',
        //         cookie: true,
        //         xfbml: true,
        //         version: 'v3.0',
        //     });

        //     window.FB.AppEvents.logPageView();
        // };

        // 加载Facebook SDK的异步函数
        // (function(d, s, id){
        //   var js, fjs = d.getElementsByTagName(s)[0];
        //   if (d.getElementById(id)) {return;}
        //   js = d.createElement(s); js.id = id;
        //   js.src = "https://connect.facebook.net/en_US/sdk.js";
        //   fjs.parentNode.insertBefore(js, fjs);
        // }(document, 'script', 'facebook-jssdk'));
    },
    mounted() {},

    computed: {
        ...mapGetters({
            token: 'users/token',
        }),
        ...mapState({
            token: 'users/token',
        }),
        type() {
            return this.inputType ? 'text' : 'password';
        },
        loginAndCreateDialog: {
            get() {
                return this.loginAndCreatePop;
            },
            set(newValue) {
                this.$emit('update:loginAndCreatePop', newValue);
            },
        },
    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        initializeLinkedInSDK() {
            this.window.IN.init({
                api_key: '86p20mqlao9hac',
                authorize: true,
            });
        },
        lingIn() {
            let that = this;
            return new Promise((resolve, reject) => {
                that.window.IN.User.authorize(() => {
                    // 登录成功后的回调处理
                    this.getUserProfile()
                        .then(userInfo => resolve(userInfo))
                        .catch(error => reject(error));
                });
            });
        },
        getUserProfile() {
            let that = this;
            return new Promise((resolve, reject) => {
                if (that.window.IN.User.isAuthorized()) {
                    that.window.IN.IN.API.Profile('me')
                        .fields('id,firstName,lastName,emailAddress')
                        .result(result => {
                            // 处理获取到的用户信息
                            const userInfo = result.values[0];
                            resolve(userInfo);
                        })
                        .error(error => {
                            // 处理错误
                            reject(error);
                        });
                } else {
                    reject(new Error('User is not authorized'));
                }
            });
        },

        //       lingIn(){
        //            const clientId = '86p20mqlao9hac';
        //       const redirectUri = 'http://localhost:9098/';
        //       const scope = ['r_liteprofile', 'r_emailaddress']

        //       window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope.join('%20')}`;

        //   window.addEventListener('message', (event) => {
        //         if (event.origin === window.location.origin) {
        //           const code = event.data;
        // console.log(code);
        //           // 使用授权码获取访问令牌
        //           this.getAccessToken(code);
        //         }
        //       });
        //       },
        //        getAccessToken(code) {
        //           // 在LinkedIn开发者平台上注册的应用程序的客户端ID
        //           const clientId = '86p20mqlao9hac';

        //           // 在LinkedIn开发者平台上注册的应用程序的客户端密钥
        //           const clientSecret = 'gQS4W3W31KYmTBnm';

        //           // 在LinkedIn开发者平台上注册的应用程序的回调URL
        //           const redirectUri = 'http://localhost:9098/';

        //           // 构建获取访问令牌的URL
        //           const tokenUrl = 'https://www.linkedin.com/oauth/v2/accessToken';

        //           // 发送POST请求获取访问令牌
        //           axios.post(tokenUrl, null, {
        //             params: {
        //               grant_type: 'authorization_code',
        //               code: code,
        //               client_id: clientId,
        //               client_secret: clientSecret,
        //               redirect_uri: redirectUri,
        //             },
        //           })
        //           .then((response) => {
        //             const accessToken = response.data.access_token;
        //             // 使用访问令牌进行后续API调用或保存到本地进行身份验证
        //           })
        //           .catch((error) => {
        //             // 处理错误情况
        //           });
        //         },

        // Facebook
        facebooklogin() {
            this.window.FB.logout(function (response) {
                console.log(response); //返回第三方的登录信息 tolen等
            });
        },
        onSignInSuccess(response) {
            this.window.FB.api('/me?fields=id,name,gender,first_name,last_name,email,birthday,hometown', dude => {});
            window.FB.api('/me', { fields: 'name,email,id,first_name,birthday,picture' }, res => {
                console.log(`res`, res);
                const name = response.name;
                const email = response.email;

                // 在这里可以将用户信息发送到后端进行进一步处理或验证
            });
            // https://developers.facebook.com/docs/graph-api/reference/user

            console.log(response); //返回第三方的登录信息 tolen等
        },
        onSignInError(error) {},

        // 注册条款
        agree() {
            this.Agreement = true;
        },
        ...mapActions({
            LoginSubmit: 'users/LoginSubmit',
            connectWebSocket: 'websocket/connectWebSocket',
        }),
        //接受兄弟组件传值

        //发送验证码
        sendCode() {
            this.errCreateFlag = false;
            if (this.createAccountForm.account == '') {
                this.errCreateFlag = true;
                this.errCreateTxt = 'Incorrect account format';
                return;
            }
            this.checkaccount();
            // .then(res => {
            //     if (res && res.code) {
            //         console.log(res);
            //         this.errCreateFlag = false;
            //         this.sucessCreaSendCodeTxt = res.msg;
            //         this.sucessCreaSendCode = true;
            //     } else {
            //         this.sucessCreaSendCodeTxt = '';
            //         this.sucessCreaSendCode = false;
            //     }
            // });
        },
        //倒计时
        async checkaccount() {
            if (!this.timer) {
                const time = 300;
                this.setTime = time;
                this.setTimeFlag = false;
                this.timer = setInterval(() => {
                    if (this.setTime > 1 && this.setTime <= time) {
                        this.setTime--;
                    } else {
                        this.setTimeFlag = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
            const params = {
                email: this.createAccountForm.account,
                source: '1',
            };
            const codeRes = await sendEmailCode(params);
            if (codeRes && codeRes.code) {
                this.errCreateFlag = false;
                this.sucessCreaSendCodeTxt = codeRes.msg;
                this.sucessCreaSendCode = true;
            } else {
                this.sucessCreaSendCodeTxt = '';
                this.sucessCreaSendCode = false;
                this.errCreateFlag = true;
                this.errCreateTxt = codeRes.msg;
                //
            }
        },
        // 发送验证码
        sendCodeForgot() {
            this.errFlagForSucess = false;
            this.errFlagForErr = false;
            if (this.forgotForm.account == '') {
                return;
            }
            this.checkForgot();
        },
        //倒计时
        async checkForgot() {
            if (!this.timerFor) {
                const time = 300;
                this.setTimeFor = time;
                this.setTimeForFlag = false;
                this.timerFor = setInterval(() => {
                    if (this.setTimeFor > 1 && this.setTimeFor <= time) {
                        this.setTimeFor--;
                    } else {
                        this.setTimeForFlag = true;
                        clearInterval(this.timerFor);
                        this.timerFor = null;
                    }
                }, 1000);
            }
            // this.errFlagForSucess = true;
            const params = {
                email: this.forgotForm.account,
                source: '3',
            };
            const codeRes = await sendEmailCode(params);
            if (codeRes && codeRes.code) {
                this.errFlagForSucess = true;
                this.sussFlagForErrTxt = codeRes.msg;
            } else {
                this.errFlagForErr = true;
                this.errFlagForErrTxt = codeRes.msg;
            }
        },
        //忘记密码下一步，第一步
        next() {
            this.$refs.forgotForm.validateField(['account', 'code'], async errMsg => {
                this.errFlagForSucess = false;
                if (errMsg) {
                    return false;
                } else {
                    const params = {
                        account: this.forgotForm.account,
                        source: '3',
                        code: this.forgotForm.code,
                    };
                    let res = await checkForgotCode(params);
                    if (res && res.code) {
                        this.errFlagForErr = false;
                        this.errFlagForErrTxt = '';
                        this.step1Show = false;
                        this.step2Show = true;
                    } else {
                        this.errFlagForErr = true;
                        this.errFlagForErrTxt = res.msg;
                    }
                }
            });
        },
        //去登录
        goToLogin() {
            this.loginAndCreateDialog = true;
            this.forgotDialog = false;
            this.tabType = 'login';
            this.$refs.loginForm.resetFields();
        },
        //去忘记密码
        goToForgotPass() {
            this.forgotDialog = true;
            this.loginAndCreateDialog = false;
            this.inputType = false;
            this.step1Show = true;
            this.step2Show = false;
            this.step3Show = false;
            this.level = []; //密码强度等级
        },
        //关闭弹窗初始化
        closeDialog() {
            this.errFlag = false;
            this.errFlagForSucess = false;
            this.errFlagForErr = false;
            this.inputType = false; //查看密码
            this.conInputType = 'password'; //查看密码
            this.tabType = 'login';
            this.$nextTick(() => {
                if (this.$refs.loginForm !== undefined) this.$refs.loginForm.resetFields();
                if (this.$refs.createAccountForm !== undefined) this.$refs.createAccountForm.resetFields();
                if (this.$refs.forgotForm !== undefined) this.$refs.forgotForm.resetFields();
            });
        },
        closeDialogagree() {
            this.Agreement = false;
        },
        //提交忘记表单
        submitForgotForm(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    this.errFlagForSucess = false;
                    const params = {
                        account: this.forgotForm.account,
                        newPasswordOne: crypto.encrypt(this.forgotForm.newPasswordOne),
                        newPasswordTow: crypto.encrypt(this.forgotForm.newPasswordTow),
                    };
                    let res = await forgetPassword(params);
                    if (res && res.code) {
                        this.errFlagForErr = false;
                        this.step1Show = false;
                        this.step2Show = false;
                        this.step3Show = true;
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'error',
                        });
                        this.errFlagForErr = true;
                        this.errFlagForErrTxt = res.msg;
                    }
                } else {
                    return false;
                }
            });
        },
        //登录
        login() {
            if (!this.loginForm.checked) {
                return;
            }
            const params = {
                username: this.loginForm.username,
                password: crypto.encrypt(this.loginForm.password),
            };
            this.LoginSubmit(params).then(res => {
                if (res.code) {
                    this.resetSetItem('token', res.data.token);
                    this.errFlag = false;
                    this.errLogTxt = '';
                    this.loginAndCreateDialog = false;
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'success',
                    });
                    bus.$emit('handleLogin', true); //是否显示登录
                    this.$emit('getLoginStatus', true);
                    this.$bus.$emit('loginabouttrue', true);
                    this.connectWebSocket(res.data.userInfo.userCode);
                } else {
                    this.$emit('getLoginStatus', false);
                    this.errFlag = true;
                    this.errLogTxt = res.msg;
                    // this.$message({
                    //     message: res.msg,
                    //     center: true,
                    //     type: 'error',
                    // });
                    return false;
                }
            });
        },
        // 提交登陆
        submitLoginForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid && this.loginForm.checked) {
                    this.login();
                } else {
                    return false;
                }
            });
        },

        //提交注册
        submitCreateForm(formName) {
            this.errFlag = false;
            this.$refs[formName].validate(valid => {
                if (valid && this.createAccountForm.checked) {
                    this.errFlag = false;
                    this.createAccount();
                } else {
                    this.$refs[formName].validate(res => {
                        // console.log(`res`, res);
                    });
                    // callback(new Error('请再次输入密码'));
                    return false;
                }
            });
        },
        //注册
        async createAccount() {
            const params = {
                account: this.createAccountForm.account,
                phone: this.createAccountForm.phone,
                code: this.createAccountForm.code,
                passwordOne: crypto.encrypt(this.createAccountForm.passwordOne),
                passwordTwo: crypto.encrypt(this.createAccountForm.passwordTwo),
            };
            let res = await register(params);

            if (res && res.code) {
                this.errCreateFlag = false;
                this.$message({
                    message: res.msg,
                    center: true,
                    type: 'success',
                });
                const params1 = {
                    username: this.createAccountForm.account,
                    password: crypto.encrypt(this.createAccountForm.passwordOne),
                };
                this.LoginSubmit(params1).then(res => {
                    if (res.code) {
                        this.resetSetItem('token', res.data.token);
                        this.errFlag = false;
                        this.errLogTxt = '';
                        this.loginAndCreateDialog = false;
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        bus.$emit('handleLogin', true); //是否显示登录
                        this.$emit('getLoginStatus', true);
                    } else {
                        this.$emit('getLoginStatus', false);
                        this.errFlag = true;
                        this.errLogTxt = res.msg;
                        // this.$message({
                        //     message: res.msg,
                        //     center: true,
                        //     type: 'error',
                        // });
                        return false;
                    }
                });
                // this.loginForm.checked=true
                // this.login()
            } else {
                this.$message({
                    message: res.msg,
                    center: true,
                    type: 'error',
                });
                this.errCreateFlag = true;
                this.errCreateTxt = res.msg;
                return false;
            }
        },
        //点击小眼睛
        showconfirmPwd() {
            if (this.conInputType === 'password') {
                this.conInputType = 'text';
            } else {
                this.conInputType = 'password';
            }
        },

        // 校验密码
        checkPassword(rule, value, callback) {
            this.level = [];
            if (!value) {
                return callback('Incorrect password format');
            }
            if (value.length < 6) {
                return callback('Password shall not be less than 6 digits');
            }
            if (value.length > 20) {
                return callback('The password is no more than 20 digits');
            }
            // 校验是数字
            const regex1 = /^\d+$/;
            // 校验字母
            const regex2 = /^[A-Za-z]+$/;
            // 校验符号
            const regex3 = /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/; // eslint-disable-line
            const regex4 = /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/; // eslint-disable-line
            const regex5 = /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/; // eslint-disable-line
            const regex6 = /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/; // eslint-disable-line
            if (regex1.test(value)) {
                this.level.push('low');
            } else if (regex2.test(value)) {
                this.level.push('low');
            } else if (regex3.test(value)) {
                this.level.push('low');
            } else if (/^[A-Za-z\d]+$/.test(value)) {
                this.level.push('low');
                this.level.push('middle');
            } else if (regex4.test(value)) {
                this.level.push('low');
                this.level.push('middle');
            } else if (regex5.test(value)) {
                this.level.push('low');
                this.level.push('middle');
            } else if (regex6.test(value)) {
                this.level.push('low');
                this.level.push('middle');
                this.level.push('high');
            }
            return callback();
        },
    },
};
</script>
<style scoped>
.login_tabs /deep/ .el-tabs__active-bar.is-top,
.login_tabs /deep/ .el-tabs__nav-wrap::after {
    display: none;
}
.login_tabs /deep/ .el-tabs__item {
    height: 22px;
    font-size: 16px;
    color: #022955;
    line-height: 18px;
    padding: 0 13px;
}
.login_tabs /deep/ .el-tabs__item::after {
    content: '';
    position: absolute;
    right: 0;
    top: 4px;
    width: 1px;
    height: 11px;
    background-color: #8497ab;
}
.login_tabs /deep/ .el-tabs__item:last-child::after {
    display: none;
}
.login_tabs /deep/ .el-tabs__header {
    margin: 16px auto 4px;
}
.login_tabs /deep/ .el-tabs__item.is-top.is-active {
    color: #1290c9;
}
.login_tabs /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
}
.err_bg {
    width: 360px;
    height: 56px;
    line-height: 44px;
    background-image: url(../assets/img/err_bg.png);
    background-repeat: no-repeat;
    background-size: 360px;
    color: #f05050;
    font-size: 12px;
    text-align: center;
    position: absolute;
    top: 0;
}
.err_bg1 {
    width: 360px;
    line-height: 18px;
    background-repeat: no-repeat;
    background-size: cover;
    color: #f05050;
    font-size: 12px;
    text-align: center;
    margin: 4px auto;
    padding: 8px;
    background-color: #fff;
    box-shadow: rgb(10 33 57 / 24%);
    box-shadow: 2px 0px 5px rgb(10 33 57 / 24%);
    border-radius: 8px;
}
.err_bg1 img,
.err_bg img {
    width: 12px;
}
.dialog_cont /deep/.el-input__inner {
    border: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
}
.dialog_cont /deep/ .el-form-item__content {
    line-height: 30px;
}
.dialog_cont /deep/ .el-form-item {
    margin-bottom: 25px;
}
.fist_form_item1 {
    margin-top: 20px;
}
.fist_form_item {
    margin-top: 50px;
}
.last_form_item {
    margin-bottom: 13px !important;
}
.dialog_cont /deep/ .el-input__inner {
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #022955;
    padding: 0;
}
.last_form_item /deep/ .el-input__inner {
    padding-right: 35px;
}
.icon_watch {
    width: 30px;
    height: 34px;
    display: inline-block;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.icon_watchun {
    background-image: url(../assets/img/watch_ic2.png);
}
.icon_watchon {
    background-image: url(../assets/img/watch_ic1.png);
}
.forgot_txt {
    font-size: 12px;
    color: #1290c9;
    line-height: 14px;
    text-decoration: underline;
    text-align: right;
    margin-bottom: 30px;
}
.btn_form_log {
    width: 100%;
    height: 54px;
    line-height: 30px;
    /* background: #1290c9; */
    background: #cccccc;
    border-radius: 14px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 5px;
}
.el-button:focus,
.el-button:hover {
    border-color: #cccccc;
    color: #ffffff;
}
.blue {
    background: #1290c9 !important;
}
.log_check .el-checkbox {
    display: flex;
    white-space: normal;
}
.log_check /deep/ .el-checkbox__inner {
    border-radius: 4px;
    width: 20px;
    height: 20px;
}
.log_check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.log_check /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.log_check /deep/ .el-checkbox__inner::after {
    width: 14px;
    height: 14px;
    left: 2px;
    top: 3px;
    background-image: url(../assets/img/check_ic.png);
    background-size: 14px 10px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}
.log_check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
    transform: rotate(0deg) scaleY(1);
}
.log_check /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: inherit;
}
.log_check /deep/ .el-checkbox__label {
    font-size: 12px;
}
.send_txt {
    font-size: 16px;
    color: #1290c9;
    line-height: 20px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 10px;
    text-decoration: underline;
    cursor: pointer;
}
.cur_pointer {
    cursor: pointer;
    font-size: 12px;
    color: #1290c9;
    line-height: 14px;
    text-decoration: underline;
    text-align: right;
    margin-bottom: 20px;
}
.send_time {
    font-size: 16px;
    color: #1290c9;
    position: absolute;
    right: 0;
    top: 0;
}
.send_code_mar /deep/ .el-input__inner {
    padding-right: 120px;
}
.psdText {
    font-size: 14px;
    margin-right: 10px;
}
.progress {
    display: inline-block;
    width: 114px;
    height: 10px;
    background: #dddddd;
    border-radius: 8px;
    margin-right: 6px;
}
.progress:last-of-type {
    margin-right: 0;
}
.low {
    background: #f56b6b;
}
.middle {
    background: #ff9629;
}
.high {
    background: #55b419;
}
.warning_txt {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 16px;
}
.btn_mar_top {
    margin-top: 40px;
}
.err_forgot {
    width: 360px;
    /* height: 34px; */
    padding: 5px 10px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.24);
    font-size: 12px;
    /* line-height: 34px; */
    text-align: center;
    border-radius: 8px;
    position: absolute;
    top: 20px;
}
.err_forgot1 {
    width: 360px;
    /* height: 34px; */
    padding: 5px 10px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.24);
    font-size: 12px;
    /* line-height: 34px; */
    text-align: center;
    border-radius: 8px;
}
.err_forgot img {
    width: 12px;
    margin-right: 4px;
}
.tip_sucess {
    color: #55b419;
}
.tip_error {
    color: #f56b6b;
}
.step3_txt {
    font-size: 16px;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 80px;
}
.el-input /deep/ .el-input__suffix {
    right: 5px !important;
}
/*注册条件 */
.agreement_cont {
    width: 100%;
    max-height: 83vh;
    overflow: auto;
    padding: 50px 56px;
}
.answer-title-italic {
    color: #000;
    font-weight: 700;
    font-style: italic;
}
.answer-content {
    padding-left: 50px;
}
.answer-title {
    color: #000;
    font-weight: 700;
}
.info1 {
    color: #172434;
    font-size: 16px;
    text-align: left;
    line-height: 22px;
    overflow: hidden;
    font-weight: 700;
}
.info1box {
    width: 840px;
    line-height: 22px;
    color: #585d64;
    font-size: 14px;
    text-align: left;

    word-break: keep-all;
}
.question {
    color: #0070c0;
    font-weight: 700;
    font-size: 14px;
}
.amail-style {
    color: #008eda;
    border-bottom: 1px solid #008eda;
}
.answer-ul {
}
.agreement_cont /deep/.el-tabs__item.is-active,
.agreement_cont /deep/.el-tabs__item:hover {
    color: #008eda;
}
.agreement_cont /deep/.el-tabs__active-bar {
    background-color: #008eda;
}
.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
@media (max-width: 821px) {
    .info1box {
        width: 100% !important;
    }
    .Quicklogin {
        text-align-last: center;
        margin: 10px auto;
        color: #cccccc;
    }
    .fb-signin-button {
        text-align: center;
        margin-right: 10px;
    }
    .Quicklogin::before,
    .Quicklogin::after {
        content: '';
        position: absolute;
        top: 61%;
        width: 36%;
        height: 1px;
        background-color: #cccccc;
    }

    .Quicklogin::before {
        left: 0;
    }

    .Quicklogin::after {
        right: 0;
    }
    .Quicklogin-img {
        display: flex;
        justify-content: center;
    }
    .Quicklogin-img img {
        height: 25px;
        width: 25px;
    }
    .linkending {
        height: 28px !important;
        width: 28px !important;
    }
}
</style>
